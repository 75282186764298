export const formats = [
  { id: "Round Robin", name: "Round Robin" },
  { id: "Single Elimination", name: "Single Elimination" },
  { id: "Double Elimination", name: "Double Elimination" },
  { id: "Pool Play", name: "Pool Play" },
  { id: "Set Schedule", name: "Set Schedule" },
  { id: "Ladder", name: "Ladder" },
  { id: "Swissholio", name: "Swissholio" },
];
export const calculatorFormats = [
  { id: "Round Robin", name: "Round Robin" },
  { id: "Single Elimination", name: "Single Elimination" },
  { id: "Double Elimination", name: "Double Elimination" },
  { id: "Switcholio", name: "Switcholio" },
  { id: "Swissholio", name: "Swissholio" },
];
export const gameStatus = [
  { id: "Setup", name: "Setup" },
  { id: "GamePlay", name: "GamePlay" },
  { id: "Bracket", name: "Bracket" },
  { id: "Complete", name: "Complete" },
];
export const scoringMethod = [
  { id: "choice", name: "Player's Choice" },
  { id: "scoremagic", name: "Force ScoreMagic" },
  { id: "basic", name: "Force Basic" },
];
export const teamGenerations = [
  { id: "Blind Draw", name: "Blind Draw" },
  { id: "Switcholio", name: "Switcholio" },
  { id: "Split Switch", name: "Split Switch" },
  { id: "Set Teams", name: "Set Teams" },
  { id: "Singles", name: "Singles" },
  { id: "Squadholio", name: "Squadholio" },
  { id: "MultiMatch", name: "MLP" },
];
export const MLPFormats = [
  { id: "A", name: "Format A (2 Doubles)" },
  { id: "B", name: "Format B (4 Doubles)" },
  { id: "C", name: "Format C (1 Singles, 2 Doubles)" },
  { id: "D", name: "Format D (2 Singles, 1 Doubles)" },
  { id: "E", name: "Format E (2 Singles, 3 Doubles)" },
  { id: "F", name: "Format F (3 Singles, 2 Doubles)" },
  { id: "G", name: "Format G (4 Singles, 2 Doubles)" },
];
export const MLPTypes = [
  { id: "Tie Breaker", name: "Tie Breaker" },
  { id: "Deciding Game", name: "Deciding Game" },
];
export const handicapTypes = [
  { id: "enhanced", name: "Enhanced" },
  // { id: "basic", name: "Basic" },
];
export const handicapFormats = [
  { id: "30day", name: "30 Day PPR" },
  { id: "120day", name: "120 Day PPR" },
  { id: "tournamentRoundPpr", name: "Dynamic Round PPR" },
  { id: "tournamentMatchPpr", name: "Dynamic Match PPR" },
];
export const dynamicHandicapStartingPPR = [
  { id: "0", name: "0" },
  { id: "30day", name: "30 Day PPR" },
  { id: "120day", name: "120 Day PPR" },
];
export const handicapWeighting = [
  { id: 5, name: "5%" },
  { id: 10, name: "10%" },
  { id: 15, name: "15%" },
  { id: 20, name: "20%" },
  { id: 25, name: "25%" },
  { id: 30, name: "30%" },
  { id: 35, name: "35%" },
  { id: 40, name: "40%" },
  { id: 45, name: "45%" },
  { id: 50, name: "50%" },
  { id: 55, name: "55%" },
  { id: 60, name: "60%" },
  { id: 65, name: "65%" },
  { id: 70, name: "70%" },
  { id: 75, name: "75%" },
  { id: 80, name: "80%" },
  { id: 85, name: "85%" },
  { id: 90, name: "90%" },
  { id: 95, name: "95%" },
  { id: 100, name: "100%" },
];

export const setTeamsGenerations = ["MultiMatch", "Set Teams"];

export const seasonTiebreakers = [
  { id: "none", name: "None" },
  { id: "totaltournaments", name: "Total Tournaments" },
  { id: "totaleligibletournaments", name: "Total Eligible Tournaments" },
  { id: "selectedppr", name: "Selected PPR" },
  { id: "selecteddpr", name: "Selected DPR" },
  { id: "seasonppr", name: "Season PPR" },
  { id: "seasondpr", name: "Season DPR" },
  { id: "overallppr", name: "Overall PPR" },
  { id: "overalldpr", name: "Overall DPR" },
  { id: "overallspr", name: "SPR" },
];
export const seasonMembershipAccess = [
  { id: "Public", name: "Public" },
  { id: "Private", name: "Private" },
  { id: "Private2", name: "Invitation Only" },
];
export const seasonOverallRankingFields = [
  { id: "show", name: "Show Overall Ranking" },
  { id: "hide", name: "Hide Overall Ranking" },
];

export const bracketSizes = [
  { id: 0, name: "All" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 8, name: "5-8" },
  { id: 16, name: "9-16" },
  { id: 32, name: "17-32" },
  { id: 64, name: "33-64" },
];
export const trophyDateRange = [
  { id: "All", name: "All Time" },
  { id: "This Year", name: "This Year" },
  { id: "Last Year", name: "Last Year" },
];

export const tournamentSingleAlerts = [
  {
    id: 1,
    name: "There is a SPORT tournament {{X}} miles away.",
  },
  {
    id: 2,
    name: "Go dominate the competition. CLUBNAME is hosting: TOURNAMENTNAME in your area. ",
  },
  {
    id: 3,
    name: "Hey {{PLAYERNAME}}, Check out this SPORT tournament.",
  },
  {
    id: 4,
    name: "There are only REGISTERED spots left! Don't miss TOURNAMENTNAME DATE.",
  },
  {
    id: 5,
    name: "Pre-Registration is open for TOURNAMENTNAME. Sign up here!",
  },
  {
    id: 6,
    name: "Pre-Registration is open for a league that begins on DATE.",
  },
  {
    id: 7,
    name: "Come out and support a great cause. CLUBNAME is hosting a SPORT fundraiser.",
  },
  {
    id: 8,
    name: "There is a SPORT fundraiser {{X}} miles from you DATE. Come help support a great cause!",
  },
  {
    id: 9,
    name: "Need an SPR boost? Check out this tournament {{X}} miles away.",
  },
];
export const tournamentAllAlerts = [
  {
    id: 1,
    name: "Check out CLUBNAME's upcoming tournaments!",
  },
  {
    id: 2,
    name: "Don't miss out on all of CLUBNAME's upcoming tournaments. Click here to find out more.",
  },
  {
    id: 2,
    name: "CLUBNAME has posted their upcoming tournaments. Check them out!",
  },
];
export const organizerSubLevels = [
  { id: 0, name: "Social" },
  { id: 1, name: "Competitive" },
  { id: 2, name: "Advanced" },
  { id: 3, name: "Pro" },
];
export const scoreLimit = [
  { id: 11, name: "11" },
  { id: 15, name: "15" },
  { id: 21, name: "21" },
  { id: 25, name: "25" },
  { id: 32, name: "32" },
  { id: 99, name: "99" },
];
export const rrTimerLength = [
  { id: 0, name: "No Timer" },
  { id: 8, name: "8" },
  { id: 10, name: "10" },
  { id: 12, name: "12" },
  { id: 15, name: "15" },
  { id: 20, name: "20" },
  { id: 25, name: "25" },
];
export const bracketTimer = [
  { id: 0, name: "No Timer" },
  { id: 8, name: "8" },
  { id: 10, name: "10" },
  { id: 12, name: "12" },
  { id: 15, name: "15" },
  { id: 20, name: "20" },
  { id: 25, name: "25" },
];
export const bocceTimers = [
  { id: 30, name: "30" },
  { id: 45, name: "45" },
  { id: 60, name: "60" },
];
export const teamNameTypes = [
  { id: "Custom Team Names", name: "Custom Team Names" },
  { id: "Display Names", name: "Display Names" },
  { id: "First and Last Name", name: "First and Last Name" },
  { id: "First Initial, Last Name", name: "First Initial, Last Name" },
  { id: "First Name, Last Initial", name: "First Name, Last Initial" },
];
export const rankBy = [
  { id: "Total Points", name: "Total Points" },
  { id: "Record then Points", name: "Record then Points" },
  { id: "Record then Differential", name: "Record then Differential" },
  { id: "PPR", name: "PPR (Points Per Round)" },
  { id: "DPR", name: "DPR (Differential Per Round)" },
];
export const gamesPerRankBy = [
  { id: "Match Win Percentage", name: "Match Win %" },
  { id: "Game Win Percentage", name: "Game Win %" },
];
export const gamesPerMatch = [
  { id: "single", name: "Single" },
  { id: "3best", name: "Best of 3" },
  { id: "5best", name: "Best of 5" },
  { id: "7best", name: "Best of 7" },
  { id: "3must", name: "Must Play 3" },
  { id: "5must", name: "Must Play 5" },
  { id: "7must", name: "Must Play 7" },
];
export const sprReason = [
  { id: "I didn't play in this game", name: "I didn't play in this game" },
  {
    id: "Game was submitted incorrectly",
    name: "Game was submitted incorrectly",
  },
  {
    id: "I suck and just want to vent about it",
    name: "I suck and just want to vent about it",
  },
  {
    id: "I won and lost SPR points",
    name: "I won and lost SPR OR SPR change is 0",
  },
];
export const sprDidNotPlay = [
  { id: "A sub played in my absence", name: "A sub played in my absence" },
  {
    id: "I registered for the tournament but could not attend",
    name: "I registered for the tournament but could not attend",
  },
  {
    id: "I did not register or attend this tournament",
    name: "I did not register or attend this tournament",
  },
  {
    id: " I had to leave the tournament early",
    name: "I had to leave the tournament early",
  },
];
export const sprWrongScore = [
  {
    id: "Overall Game score is flipped",
    name: "Overall Game score is flipped",
  },
  {
    id: "Game score completely wrong",
    name: "Game score completely wrong",
  },
  {
    id: "Round scores (PPR) were incorrect",
    name: "Round scores (PPR) were incorrect",
  },
  {
    id: "Played against a ghost",
    name: "Played against a ghost",
  },
];
export const locationRange = [
  { id: "2mi", name: "2mi" },
  { id: "10mi", name: "10mi" },
  { id: "25mi", name: "25mi" },
  { id: "50mi", name: "50mi" },
  { id: "75mi", name: "75mi" },
  { id: "100mi", name: "100mi" },
  { id: "200mi", name: "200mi" },
  { id: "300mi", name: "300mi" },
];
export const tShirtSizes = [
  { id: "Unspecified", name: "Unspecified" },
  { id: "XS", name: "XS" },
  { id: "S", name: "S" },
  { id: "M", name: "M" },
  { id: "L", name: "L" },
  { id: "XL", name: "XL" },
  { id: "2XL", name: "2XL" },
  { id: "3XL", name: "3XL" },
  { id: "4XL", name: "4XL" },
];

export const sportsNames = {
  shortHand: [
    "21 or Bust",
    "Axe Throwing",
    "Billiards",
    "Bocce",
    "Cornhole",
    "Darts",
    "Horseshoes",
    "Pickleball",
    "Pong",
    "Shuffleboard",
    "Table Tennis",
    "Tennis",
    "Volleyball",
    "Other Sport",
  ],
  longHand: [
    { sport: "21bust", name: "21 or Bust" },
    { sport: "axethrowing", name: "Axe Throwing" },
    { sport: "billiards", name: "Billiards" },
    { sport: "bocce", name: "Bocce" },
    { sport: "cornhole", name: "Cornhole" },
    { sport: "darts", name: "Darts" },
    { sport: "horseshoes", name: "Horseshoes" },
    { sport: "pickleball", name: "Pickleball" },
    { sport: "pong", name: "Pong" },
    { sport: "tableshuffleboard", name: "Table Shuffleboard" },
    { sport: "tabletennis", name: "Table Tennis" },
    { sport: "tennis", name: "Tennis" },
    { sport: "volleyball", name: "Volleyball" },
    { sport: "other", name: "Other Sport" },
  ],
};

export const scoreMagicMinMaxValues = [
  {
    sport: "horseshoes",
    dpr: { min: -6, max: 6 },
    ppr: { min: 0, max: 6 },
    spr: { min: 0, max: 100 },
    seasonPpr: { min: 0, max: 6 },
    seasonDpr: { min: -6, max: 6 },
    seasonTier: { min: 1, max: 9 },
    seasonMember: { min: 1, max: 9 },
    duprSingles: { min: 2, max: 8 },
    duprDoubles: { min: 2, max: 8 },
    duprExists: { min: 0, max: 100 },
  },
  {
    sport: "cornhole",
    dpr: { min: -12, max: 12 },
    ppr: { min: 0, max: 12 },
    spr: { min: 0, max: 100 },
    seasonPpr: { min: 0, max: 12 },
    seasonDpr: { min: -12, max: 12 },
    seasonTier: { min: 1, max: 9 },
    seasonMember: { min: 1, max: 9 },
    duprSingles: { min: 2, max: 8 },
    duprDoubles: { min: 2, max: 8 },
    duprExists: { min: 0, max: 100 },
  },
  {
    sport: "all",
    spr: { min: 0, max: 100 },
    dpr: { min: -12, max: 12 },
    ppr: { min: 0, max: 12 },
    seasonPpr: { min: 0, max: 12 },
    seasonDpr: { min: -12, max: 12 },
    seasonTier: { min: 1, max: 9 },
    seasonMember: { min: 1, max: 9 },
    duprSingles: { min: 2, max: 8 },
    duprDoubles: { min: 2, max: 8 },
    duprExists: { min: 0, max: 100 },
  },
];

export const courtColors = [
  { id: 1, name: "#4E2A84" },
  { id: 2, name: "#0077B5" },
  { id: 3, name: "#EE7600" },
  { id: 4, name: "#009900" },
  { id: 5, name: "#006260" },
  { id: 6, name: "#663300" },
  { id: 7, name: "#cc9900" },
  { id: 8, name: "#cc0000" },
  { id: 9, name: "#500000" },
  { id: 10, name: "#305a15" },
  { id: 11, name: "#856133" },
  { id: 12, name: "#94514d" },
  { id: 13, name: "#5d104c" },
  { id: 14, name: "#243346" },
  { id: 15, name: "#8d2e0e" },
  { id: 16, name: "#00716d" },
];

export function ScoreLimitBySport(sport) {
  let limitArr;
  function MappedLimits(arr) {
    let limits = arr.map((l) => {
      return {
        id: l,
        name: l.toString(),
      };
    });
    return limits;
  }

  console.log("ScoreLimitBySport sport: ", sport);

  switch (sport) {
    case "darts":
      limitArr = MappedLimits([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 99,
      ]);
      break;
    case "billiards":
      limitArr = MappedLimits([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 99,
      ]);
      break;
    case "volleyball":
      limitArr = MappedLimits([21, 25, 99]);
      break;
    case "tennis":
      limitArr = MappedLimits([4, 6, 8, 99]);
      break;
    case "tabletennis":
      limitArr = MappedLimits([11, 21, 99]);
      break;
    case "tableshuffleboard":
      limitArr = MappedLimits([15, 21, 64, 99]);
      break;
    case "pong":
      limitArr = MappedLimits([10, 15, 99]);
      break;
    case "pickleball":
      limitArr = MappedLimits([11, 15, 21, 99]);
      break;
    case "bocce":
      limitArr = MappedLimits([10, 11, 12, 13, 14, 15, 16, 21, 99]);
      break;
    case "21bust":
      limitArr = MappedLimits([21, 99]);
      break;
    case "horseshoes":
      limitArr = MappedLimits([21, 36, 40, 99]);
      break;
    case "cornhole":
      limitArr = MappedLimits([11, 15, 21, 25, 32, 99]);
      break;
    case "axethrowing":
      limitArr = MappedLimits([72, 99]);
      break;
    default:
      limitArr = MappedLimits([99]);
  }
  console.log("limitArr: ", limitArr);
  return limitArr;
}

export const allAccessUsers = [
  "Dj3AAXk8IzSyLTKpkVho", //Nick
  "7vJGznndkeOAE41lDgCu", //Phil
  "63cVpKrCthaqEQ1eGtrG", //Ben
  "HyitXUI17Zq3l4c8gq67", //Ben's recruiter accnt
  "5BQeMjDh6VGMMIm2LS6e", //Kristen
  "lSQEPXTrUDiJrePSB6kt", //James
  "63cUxlUIDPIiMLYo0joL",
  "IDTX7ilkl8Px6HwcDok7",
  "AFv0HcooA43ZhAHlXY6J",
  "PFjag6rcrlHcd0RB9oEN",
  "29etZJMDaiPVpF3kFuGx",
];

export const allAccessOrganizers = [
  "PdejK7ZzhMM5nGJPl6QP57wBXBB2",
  "FD2x0TUi1qTD78xj3vdFcSnzW102", //Phil Main
  "irpWsXBhjQRFhK8pNrtnarqDpeF2",
  "ykQkJvXkI6U5nxuAqDyz82z5Fdt1", //Ben
  "AxNqt2L9sYhyme7ucfrfUpMlQVL2", //Kristen
  "YG6V01K5b4VtFwi4GgswLNyAgZD3", //James
  "KjzCkPnsCaWZSe96esO1rV9OJrP2", //betatest1

  // "n8UyD5GoSIZtn8GvSnTHSaMBVNb2", // phil@4bagr.app
];


 export const isDevelopment =
    window.location.hostname.startsWith("beta") ||
    window.location.hostname.startsWith("localhost");

export const timezones = [
  { id: "Africa/Abidjan", name: "Africa/Abidjan" },
  { id: "Africa/Accra", name: "Africa/Accra" },
  { id: "Africa/Addis_Ababa", name: "Africa/Addis_Ababa" },
  { id: "Africa/Algiers", name: "Africa/Algiers" },
  { id: "Africa/Asmara", name: "Africa/Asmara" },
  { id: "Africa/Asmera", name: "Africa/Asmera" },
  { id: "Africa/Bamako", name: "Africa/Bamako" },
  { id: "Africa/Bangui", name: "Africa/Bangui" },
  { id: "Africa/Banjul", name: "Africa/Banjul" },
  { id: "Africa/Bissau", name: "Africa/Bissau" },
  { id: "Africa/Blantyre", name: "Africa/Blantyre" },
  { id: "Africa/Brazzaville", name: "Africa/Brazzaville" },
  { id: "Africa/Bujumbura", name: "Africa/Bujumbura" },
  { id: "Africa/Cairo", name: "Africa/Cairo" },
  { id: "Africa/Casablanca", name: "Africa/Casablanca" },
  { id: "Africa/Ceuta", name: "Africa/Ceuta" },
  { id: "Africa/Conakry", name: "Africa/Conakry" },
  { id: "Africa/Dakar", name: "Africa/Dakar" },
  { id: "Africa/Dar_es_Salaam", name: "Africa/Dar_es_Salaam" },
  { id: "Africa/Djibouti", name: "Africa/Djibouti" },
  { id: "Africa/Douala", name: "Africa/Douala" },
  { id: "Africa/El_Aaiun", name: "Africa/El_Aaiun" },
  { id: "Africa/Freetown", name: "Africa/Freetown" },
  { id: "Africa/Gaborone", name: "Africa/Gaborone" },
  { id: "Africa/Harare", name: "Africa/Harare" },
  { id: "Africa/Johannesburg", name: "Africa/Johannesburg" },
  { id: "Africa/Juba", name: "Africa/Juba" },
  { id: "Africa/Kampala", name: "Africa/Kampala" },
  { id: "Africa/Khartoum", name: "Africa/Khartoum" },
  { id: "Africa/Kigali", name: "Africa/Kigali" },
  { id: "Africa/Kinshasa", name: "Africa/Kinshasa" },
  { id: "Africa/Lagos", name: "Africa/Lagos" },
  { id: "Africa/Libreville", name: "Africa/Libreville" },
  { id: "Africa/Lome", name: "Africa/Lome" },
  { id: "Africa/Luanda", name: "Africa/Luanda" },
  { id: "Africa/Lubumbashi", name: "Africa/Lubumbashi" },
  { id: "Africa/Lusaka", name: "Africa/Lusaka" },
  { id: "Africa/Malabo", name: "Africa/Malabo" },
  { id: "Africa/Maputo", name: "Africa/Maputo" },
  { id: "Africa/Maseru", name: "Africa/Maseru" },
  { id: "Africa/Mbabane", name: "Africa/Mbabane" },
  { id: "Africa/Mogadishu", name: "Africa/Mogadishu" },
  { id: "Africa/Monrovia", name: "Africa/Monrovia" },
  { id: "Africa/Nairobi", name: "Africa/Nairobi" },
  { id: "Africa/Ndjamena", name: "Africa/Ndjamena" },
  { id: "Africa/Niamey", name: "Africa/Niamey" },
  { id: "Africa/Nouakchott", name: "Africa/Nouakchott" },
  { id: "Africa/Ouagadougou", name: "Africa/Ouagadougou" },
  { id: "Africa/Porto-Novo", name: "Africa/Porto-Novo" },
  { id: "Africa/Sao_Tome", name: "Africa/Sao_Tome" },
  { id: "Africa/Timbuktu", name: "Africa/Timbuktu" },
  { id: "Africa/Tripoli", name: "Africa/Tripoli" },
  { id: "Africa/Tunis", name: "Africa/Tunis" },
  { id: "Africa/Windhoek", name: "Africa/Windhoek" },
  { id: "America/Adak", name: "America/Adak" },
  { id: "America/Anchorage", name: "America/Anchorage" },
  { id: "America/Anguilla", name: "America/Anguilla" },
  { id: "America/Antigua", name: "America/Antigua" },
  { id: "America/Araguaina", name: "America/Araguaina" },
  {
    id: "America/Argentina/Buenos_Aires",
    name: "America/Argentina/Buenos_Aires",
  },
  { id: "America/Argentina/Catamarca", name: "America/Argentina/Catamarca" },
  {
    id: "America/Argentina/ComodRivadavia",
    name: "America/Argentina/ComodRivadavia",
  },
  { id: "America/Argentina/Cordoba", name: "America/Argentina/Cordoba" },
  { id: "America/Argentina/Jujuy", name: "America/Argentina/Jujuy" },
  { id: "America/Argentina/La_Rioja", name: "America/Argentina/La_Rioja" },
  { id: "America/Argentina/Mendoza", name: "America/Argentina/Mendoza" },
  {
    id: "America/Argentina/Rio_Gallegos",
    name: "America/Argentina/Rio_Gallegos",
  },
  { id: "America/Argentina/Salta", name: "America/Argentina/Salta" },
  { id: "America/Argentina/San_Juan", name: "America/Argentina/San_Juan" },
  { id: "America/Argentina/San_Luis", name: "America/Argentina/San_Luis" },
  { id: "America/Argentina/Tucuman", name: "America/Argentina/Tucuman" },
  { id: "America/Argentina/Ushuaia", name: "America/Argentina/Ushuaia" },
  { id: "America/Aruba", name: "America/Aruba" },
  { id: "America/Asuncion", name: "America/Asuncion" },
  { id: "America/Atikokan", name: "America/Atikokan" },
  { id: "America/Atka", name: "America/Atka" },
  { id: "America/Bahia", name: "America/Bahia" },
  { id: "America/Bahia_Banderas", name: "America/Bahia_Banderas" },
  { id: "America/Barbados", name: "America/Barbados" },
  { id: "America/Belem", name: "America/Belem" },
  { id: "America/Belize", name: "America/Belize" },
  { id: "America/Blanc-Sablon", name: "America/Blanc-Sablon" },
  { id: "America/Boa_Vista", name: "America/Boa_Vista" },
  { id: "America/Bogota", name: "America/Bogota" },
  { id: "America/Boise", name: "America/Boise" },
  { id: "America/Buenos_Aires", name: "America/Buenos_Aires" },
  { id: "America/Cambridge_Bay", name: "America/Cambridge_Bay" },
  { id: "America/Campo_Grande", name: "America/Campo_Grande" },
  { id: "America/Cancun", name: "America/Cancun" },
  { id: "America/Caracas", name: "America/Caracas" },
  { id: "America/Catamarca", name: "America/Catamarca" },
  { id: "America/Cayenne", name: "America/Cayenne" },
  { id: "America/Cayman", name: "America/Cayman" },
  { id: "America/Chicago", name: "America/Chicago" },
  { id: "America/Chihuahua", name: "America/Chihuahua" },
  { id: "America/Coral_Harbour", name: "America/Coral_Harbour" },
  { id: "America/Cordoba", name: "America/Cordoba" },
  { id: "America/Costa_Rica", name: "America/Costa_Rica" },
  { id: "America/Creston", name: "America/Creston" },
  { id: "America/Cuiaba", name: "America/Cuiaba" },
  { id: "America/Curacao", name: "America/Curacao" },
  { id: "America/Danmarkshavn", name: "America/Danmarkshavn" },
  { id: "America/Dawson", name: "America/Dawson" },
  { id: "America/Dawson_Creek", name: "America/Dawson_Creek" },
  { id: "America/Denver", name: "America/Denver" },
  { id: "America/Detroit", name: "America/Detroit" },
  { id: "America/Dominica", name: "America/Dominica" },
  { id: "America/Edmonton", name: "America/Edmonton" },
  { id: "America/Eirunepe", name: "America/Eirunepe" },
  { id: "America/El_Salvador", name: "America/El_Salvador" },
  { id: "America/Ensenada", name: "America/Ensenada" },
  { id: "America/Fort_Nelson", name: "America/Fort_Nelson" },
  { id: "America/Fort_Wayne", name: "America/Fort_Wayne" },
  { id: "America/Fortaleza", name: "America/Fortaleza" },
  { id: "America/Glace_Bay", name: "America/Glace_Bay" },
  { id: "America/Godthab", name: "America/Godthab" },
  { id: "America/Goose_Bay", name: "America/Goose_Bay" },
  { id: "America/Grand_Turk", name: "America/Grand_Turk" },
  { id: "America/Grenada", name: "America/Grenada" },
  { id: "America/Guadeloupe", name: "America/Guadeloupe" },
  { id: "America/Guatemala", name: "America/Guatemala" },
  { id: "America/Guayaquil", name: "America/Guayaquil" },
  { id: "America/Guyana", name: "America/Guyana" },
  { id: "America/Halifax", name: "America/Halifax" },
  { id: "America/Havana", name: "America/Havana" },
  { id: "America/Hermosillo", name: "America/Hermosillo" },
  { id: "America/Indiana/Indianapolis", name: "America/Indiana/Indianapolis" },
  { id: "America/Indiana/Knox", name: "America/Indiana/Knox" },
  { id: "America/Indiana/Marengo", name: "America/Indiana/Marengo" },
  { id: "America/Indiana/Petersburg", name: "America/Indiana/Petersburg" },
  { id: "America/Indiana/Tell_City", name: "America/Indiana/Tell_City" },
  { id: "America/Indiana/Vevay", name: "America/Indiana/Vevay" },
  { id: "America/Indiana/Vincennes", name: "America/Indiana/Vincennes" },
  { id: "America/Indiana/Winamac", name: "America/Indiana/Winamac" },
  { id: "America/Indianapolis", name: "America/Indianapolis" },
  { id: "America/Inuvik", name: "America/Inuvik" },
  { id: "America/Iqaluit", name: "America/Iqaluit" },
  { id: "America/Jamaica", name: "America/Jamaica" },
  { id: "America/Jujuy", name: "America/Jujuy" },
  { id: "America/Juneau", name: "America/Juneau" },
  { id: "America/Kentucky/Louisville", name: "America/Kentucky/Louisville" },
  { id: "America/Kentucky/Monticello", name: "America/Kentucky/Monticello" },
  { id: "America/Knox_IN", name: "America/Knox_IN" },
  { id: "America/Kralendijk", name: "America/Kralendijk" },
  { id: "America/La_Paz", name: "America/La_Paz" },
  { id: "America/Lima", name: "America/Lima" },
  { id: "America/Los_Angeles", name: "America/Los_Angeles" },
  { id: "America/Louisville", name: "America/Louisville" },
  { id: "America/Lower_Princes", name: "America/Lower_Princes" },
  { id: "America/Maceio", name: "America/Maceio" },
  { id: "America/Managua", name: "America/Managua" },
  { id: "America/Manaus", name: "America/Manaus" },
  { id: "America/Marigot", name: "America/Marigot" },
  { id: "America/Martinique", name: "America/Martinique" },
  { id: "America/Matamoros", name: "America/Matamoros" },
  { id: "America/Mazatlan", name: "America/Mazatlan" },
  { id: "America/Mendoza", name: "America/Mendoza" },
  { id: "America/Menominee", name: "America/Menominee" },
  { id: "America/Merida", name: "America/Merida" },
  { id: "America/Metlakatla", name: "America/Metlakatla" },
  { id: "America/Mexico_City", name: "America/Mexico_City" },
  { id: "America/Miquelon", name: "America/Miquelon" },
  { id: "America/Moncton", name: "America/Moncton" },
  { id: "America/Monterrey", name: "America/Monterrey" },
  { id: "America/Montevideo", name: "America/Montevideo" },
  { id: "America/Montreal", name: "America/Montreal" },
  { id: "America/Montserrat", name: "America/Montserrat" },
  { id: "America/Nassau", name: "America/Nassau" },
  { id: "America/New_York", name: "America/New_York" },
  { id: "America/Nipigon", name: "America/Nipigon" },
  { id: "America/Nome", name: "America/Nome" },
  { id: "America/Noronha", name: "America/Noronha" },
  { id: "America/North_Dakota/Beulah", name: "America/North_Dakota/Beulah" },
  { id: "America/North_Dakota/Center", name: "America/North_Dakota/Center" },
  {
    id: "America/North_Dakota/New_Salem",
    name: "America/North_Dakota/New_Salem",
  },
  { id: "America/Ojinaga", name: "America/Ojinaga" },
  { id: "America/Panama", name: "America/Panama" },
  { id: "America/Pangnirtung", name: "America/Pangnirtung" },
  { id: "America/Paramaribo", name: "America/Paramaribo" },
  { id: "America/Phoenix", name: "America/Phoenix" },
  { id: "America/Port-au-Prince", name: "America/Port-au-Prince" },
  { id: "America/Port_of_Spain", name: "America/Port_of_Spain" },
  { id: "America/Porto_Acre", name: "America/Porto_Acre" },
  { id: "America/Porto_Velho", name: "America/Porto_Velho" },
  { id: "America/Puerto_Rico", name: "America/Puerto_Rico" },
  { id: "America/Punta_Arenas", name: "America/Punta_Arenas" },
  { id: "America/Rainy_River", name: "America/Rainy_River" },
  { id: "America/Rankin_Inlet", name: "America/Rankin_Inlet" },
  { id: "America/Recife", name: "America/Recife" },
  { id: "America/Regina", name: "America/Regina" },
  { id: "America/Resolute", name: "America/Resolute" },
  { id: "America/Rio_Branco", name: "America/Rio_Branco" },
  { id: "America/Rosario", name: "America/Rosario" },
  { id: "America/Santa_Isabel", name: "America/Santa_Isabel" },
  { id: "America/Santarem", name: "America/Santarem" },
  { id: "America/Santiago", name: "America/Santiago" },
  { id: "America/Santo_Domingo", name: "America/Santo_Domingo" },
  { id: "America/Sao_Paulo", name: "America/Sao_Paulo" },
  { id: "America/Scoresbysund", name: "America/Scoresbysund" },
  { id: "America/Shiprock", name: "America/Shiprock" },
  { id: "America/Sitka", name: "America/Sitka" },
  { id: "America/St_Barthelemy", name: "America/St_Barthelemy" },
  { id: "America/St_Johns", name: "America/St_Johns" },
  { id: "America/St_Kitts", name: "America/St_Kitts" },
  { id: "America/St_Lucia", name: "America/St_Lucia" },
  { id: "America/St_Thomas", name: "America/St_Thomas" },
  { id: "America/St_Vincent", name: "America/St_Vincent" },
  { id: "America/Swift_Current", name: "America/Swift_Current" },
  { id: "America/Tegucigalpa", name: "America/Tegucigalpa" },
  { id: "America/Thule", name: "America/Thule" },
  { id: "America/Thunder_Bay", name: "America/Thunder_Bay" },
  { id: "America/Tijuana", name: "America/Tijuana" },
  { id: "America/Toronto", name: "America/Toronto" },
  { id: "America/Tortola", name: "America/Tortola" },
  { id: "America/Vancouver", name: "America/Vancouver" },
  { id: "America/Virgin", name: "America/Virgin" },
  { id: "America/Whitehorse", name: "America/Whitehorse" },
  { id: "America/Winnipeg", name: "America/Winnipeg" },
  { id: "America/Yakutat", name: "America/Yakutat" },
  { id: "America/Yellowknife", name: "America/Yellowknife" },
  { id: "Antarctica/Casey", name: "Antarctica/Casey" },
  { id: "Antarctica/Davis", name: "Antarctica/Davis" },
  { id: "Antarctica/DumontDUrville", name: "Antarctica/DumontDUrville" },
  { id: "Antarctica/Macquarie", name: "Antarctica/Macquarie" },
  { id: "Antarctica/Mawson", name: "Antarctica/Mawson" },
  { id: "Antarctica/McMurdo", name: "Antarctica/McMurdo" },
  { id: "Antarctica/Palmer", name: "Antarctica/Palmer" },
  { id: "Antarctica/Rothera", name: "Antarctica/Rothera" },
  { id: "Antarctica/South_Pole", name: "Antarctica/South_Pole" },
  { id: "Antarctica/Syowa", name: "Antarctica/Syowa" },
  { id: "Antarctica/Troll", name: "Antarctica/Troll" },
  { id: "Antarctica/Vostok", name: "Antarctica/Vostok" },
  { id: "Arctic/Longyearbyen", name: "Arctic/Longyearbyen" },
  { id: "Asia/Aden", name: "Asia/Aden" },
  { id: "Asia/Almaty", name: "Asia/Almaty" },
  { id: "Asia/Amman", name: "Asia/Amman" },
  { id: "Asia/Anadyr", name: "Asia/Anadyr" },
  { id: "Asia/Aqtau", name: "Asia/Aqtau" },
  { id: "Asia/Aqtobe", name: "Asia/Aqtobe" },
  { id: "Asia/Ashgabat", name: "Asia/Ashgabat" },
  { id: "Asia/Ashkhabad", name: "Asia/Ashkhabad" },
  { id: "Asia/Atyrau", name: "Asia/Atyrau" },
  { id: "Asia/Baghdad", name: "Asia/Baghdad" },
  { id: "Asia/Bahrain", name: "Asia/Bahrain" },
  { id: "Asia/Baku", name: "Asia/Baku" },
  { id: "Asia/Bangkok", name: "Asia/Bangkok" },
  { id: "Asia/Barnaul", name: "Asia/Barnaul" },
  { id: "Asia/Beirut", name: "Asia/Beirut" },
  { id: "Asia/Bishkek", name: "Asia/Bishkek" },
  { id: "Asia/Brunei", name: "Asia/Brunei" },
  { id: "Asia/Calcutta", name: "Asia/Calcutta" },
  { id: "Asia/Chita", name: "Asia/Chita" },
  { id: "Asia/Choibalsan", name: "Asia/Choibalsan" },
  { id: "Asia/Chongqing", name: "Asia/Chongqing" },
  { id: "Asia/Chungking", name: "Asia/Chungking" },
  { id: "Asia/Colombo", name: "Asia/Colombo" },
  { id: "Asia/Dacca", name: "Asia/Dacca" },
  { id: "Asia/Damascus", name: "Asia/Damascus" },
  { id: "Asia/Dhaka", name: "Asia/Dhaka" },
  { id: "Asia/Dili", name: "Asia/Dili" },
  { id: "Asia/Dubai", name: "Asia/Dubai" },
  { id: "Asia/Dushanbe", name: "Asia/Dushanbe" },
  { id: "Asia/Famagusta", name: "Asia/Famagusta" },
  { id: "Asia/Gaza", name: "Asia/Gaza" },
  { id: "Asia/Harbin", name: "Asia/Harbin" },
  { id: "Asia/Hebron", name: "Asia/Hebron" },
  { id: "Asia/Ho_Chi_Minh", name: "Asia/Ho_Chi_Minh" },
  { id: "Asia/Hong_Kong", name: "Asia/Hong_Kong" },
  { id: "Asia/Hovd", name: "Asia/Hovd" },
  { id: "Asia/Irkutsk", name: "Asia/Irkutsk" },
  { id: "Asia/Istanbul", name: "Asia/Istanbul" },
  { id: "Asia/Jakarta", name: "Asia/Jakarta" },
  { id: "Asia/Jayapura", name: "Asia/Jayapura" },
  { id: "Asia/Jerusalem", name: "Asia/Jerusalem" },
  { id: "Asia/Kabul", name: "Asia/Kabul" },
  { id: "Asia/Kamchatka", name: "Asia/Kamchatka" },
  { id: "Asia/Karachi", name: "Asia/Karachi" },
  { id: "Asia/Kashgar", name: "Asia/Kashgar" },
  { id: "Asia/Kathmandu", name: "Asia/Kathmandu" },
  { id: "Asia/Katmandu", name: "Asia/Katmandu" },
  { id: "Asia/Khandyga", name: "Asia/Khandyga" },
  { id: "Asia/Kolkata", name: "Asia/Kolkata" },
  { id: "Asia/Krasnoyarsk", name: "Asia/Krasnoyarsk" },
  { id: "Asia/Kuala_Lumpur", name: "Asia/Kuala_Lumpur" },
  { id: "Asia/Kuching", name: "Asia/Kuching" },
  { id: "Asia/Kuwait", name: "Asia/Kuwait" },
  { id: "Asia/Macao", name: "Asia/Macao" },
  { id: "Asia/Macau", name: "Asia/Macau" },
  { id: "Asia/Magadan", name: "Asia/Magadan" },
  { id: "Asia/Makassar", name: "Asia/Makassar" },
  { id: "Asia/Manila", name: "Asia/Manila" },
  { id: "Asia/Muscat", name: "Asia/Muscat" },
  { id: "Asia/Nicosia", name: "Asia/Nicosia" },
  { id: "Asia/Novokuznetsk", name: "Asia/Novokuznetsk" },
  { id: "Asia/Novosibirsk", name: "Asia/Novosibirsk" },
  { id: "Asia/Omsk", name: "Asia/Omsk" },
  { id: "Asia/Oral", name: "Asia/Oral" },
  { id: "Asia/Phnom_Penh", name: "Asia/Phnom_Penh" },
  { id: "Asia/Pontianak", name: "Asia/Pontianak" },
  { id: "Asia/Pyongyang", name: "Asia/Pyongyang" },
  { id: "Asia/Qatar", name: "Asia/Qatar" },
  { id: "Asia/Qyzylorda", name: "Asia/Qyzylorda" },
  { id: "Asia/Rangoon", name: "Asia/Rangoon" },
  { id: "Asia/Riyadh", name: "Asia/Riyadh" },
  { id: "Asia/Saigon", name: "Asia/Saigon" },
  { id: "Asia/Sakhalin", name: "Asia/Sakhalin" },
  { id: "Asia/Samarkand", name: "Asia/Samarkand" },
  { id: "Asia/Seoul", name: "Asia/Seoul" },
  { id: "Asia/Shanghai", name: "Asia/Shanghai" },
  { id: "Asia/Singapore", name: "Asia/Singapore" },
  { id: "Asia/Srednekolymsk", name: "Asia/Srednekolymsk" },
  { id: "Asia/Taipei", name: "Asia/Taipei" },
  { id: "Asia/Tashkent", name: "Asia/Tashkent" },
  { id: "Asia/Tbilisi", name: "Asia/Tbilisi" },
  { id: "Asia/Tehran", name: "Asia/Tehran" },
  { id: "Asia/Tel_Aviv", name: "Asia/Tel_Aviv" },
  { id: "Asia/Thimbu", name: "Asia/Thimbu" },
  { id: "Asia/Thimphu", name: "Asia/Thimphu" },
  { id: "Asia/Tokyo", name: "Asia/Tokyo" },
  { id: "Asia/Tomsk", name: "Asia/Tomsk" },
  { id: "Asia/Ujung_Pandang", name: "Asia/Ujung_Pandang" },
  { id: "Asia/Ulaanbaatar", name: "Asia/Ulaanbaatar" },
  { id: "Asia/Ulan_Bator", name: "Asia/Ulan_Bator" },
  { id: "Asia/Urumqi", name: "Asia/Urumqi" },
  { id: "Asia/Ust-Nera", name: "Asia/Ust-Nera" },
  { id: "Asia/Vientiane", name: "Asia/Vientiane" },
  { id: "Asia/Vladivostok", name: "Asia/Vladivostok" },
  { id: "Asia/Yakutsk", name: "Asia/Yakutsk" },
  { id: "Asia/Yangon", name: "Asia/Yangon" },
  { id: "Asia/Yekaterinburg", name: "Asia/Yekaterinburg" },
  { id: "Asia/Yerevan", name: "Asia/Yerevan" },
  { id: "Atlantic/Azores", name: "Atlantic/Azores" },
  { id: "Atlantic/Bermuda", name: "Atlantic/Bermuda" },
  { id: "Atlantic/Canary", name: "Atlantic/Canary" },
  { id: "Atlantic/Cape_Verde", name: "Atlantic/Cape_Verde" },
  { id: "Atlantic/Faeroe", name: "Atlantic/Faeroe" },
  { id: "Atlantic/Faroe", name: "Atlantic/Faroe" },
  { id: "Atlantic/Jan_Mayen", name: "Atlantic/Jan_Mayen" },
  { id: "Atlantic/Madeira", name: "Atlantic/Madeira" },
  { id: "Atlantic/Reykjavik", name: "Atlantic/Reykjavik" },
  { id: "Atlantic/South_Georgia", name: "Atlantic/South_Georgia" },
  { id: "Atlantic/St_Helena", name: "Atlantic/St_Helena" },
  { id: "Atlantic/Stanley", name: "Atlantic/Stanley" },
  { id: "Australia/ACT", name: "Australia/ACT" },
  { id: "Australia/Adelaide", name: "Australia/Adelaide" },
  { id: "Australia/Brisbane", name: "Australia/Brisbane" },
  { id: "Australia/Broken_Hill", name: "Australia/Broken_Hill" },
  { id: "Australia/Canberra", name: "Australia/Canberra" },
  { id: "Australia/Currie", name: "Australia/Currie" },
  { id: "Australia/Darwin", name: "Australia/Darwin" },
  { id: "Australia/Eucla", name: "Australia/Eucla" },
  { id: "Australia/Hobart", name: "Australia/Hobart" },
  { id: "Australia/LHI", name: "Australia/LHI" },
  { id: "Australia/Lindeman", name: "Australia/Lindeman" },
  { id: "Australia/Lord_Howe", name: "Australia/Lord_Howe" },
  { id: "Australia/Melbourne", name: "Australia/Melbourne" },
  { id: "Australia/NSW", name: "Australia/NSW" },
  { id: "Australia/North", name: "Australia/North" },
  { id: "Australia/Perth", name: "Australia/Perth" },
  { id: "Australia/Queensland", name: "Australia/Queensland" },
  { id: "Australia/South", name: "Australia/South" },
  { id: "Australia/Sydney", name: "Australia/Sydney" },
  { id: "Australia/Tasmania", name: "Australia/Tasmania" },
  { id: "Australia/Victoria", name: "Australia/Victoria" },
  { id: "Australia/West", name: "Australia/West" },
  { id: "Australia/Yancowinna", name: "Australia/Yancowinna" },
  { id: "Brazil/Acre", name: "Brazil/Acre" },
  { id: "Brazil/DeNoronha", name: "Brazil/DeNoronha" },
  { id: "Brazil/East", name: "Brazil/East" },
  { id: "Brazil/West", name: "Brazil/West" },
  { id: "CET", name: "CET" },
  { id: "CST6CDT", name: "CST6CDT" },
  { id: "Canada/Atlantic", name: "Canada/Atlantic" },
  { id: "Canada/Central", name: "Canada/Central" },
  { id: "Canada/Eastern", name: "Canada/Eastern" },
  { id: "Canada/Mountain", name: "Canada/Mountain" },
  { id: "Canada/Newfoundland", name: "Canada/Newfoundland" },
  { id: "Canada/Pacific", name: "Canada/Pacific" },
  { id: "Canada/Saskatchewan", name: "Canada/Saskatchewan" },
  { id: "Canada/Yukon", name: "Canada/Yukon" },
  { id: "Chile/Continental", name: "Chile/Continental" },
  { id: "Chile/EasterIsland", name: "Chile/EasterIsland" },
  { id: "Cuba", name: "Cuba" },
  { id: "EET", name: "EET" },
  { id: "EST", name: "EST" },
  { id: "EST5EDT", name: "EST5EDT" },
  { id: "Egypt", name: "Egypt" },
  { id: "Eire", name: "Eire" },
  { id: "Etc/GMT", name: "Etc/GMT" },
  { id: "Etc/GMT+0", name: "Etc/GMT+0" },
  { id: "Etc/GMT+1", name: "Etc/GMT+1" },
  { id: "Etc/GMT+10", name: "Etc/GMT+10" },
  { id: "Etc/GMT+11", name: "Etc/GMT+11" },
  { id: "Etc/GMT+12", name: "Etc/GMT+12" },
  { id: "Etc/GMT+2", name: "Etc/GMT+2" },
  { id: "Etc/GMT+3", name: "Etc/GMT+3" },
  { id: "Etc/GMT+4", name: "Etc/GMT+4" },
  { id: "Etc/GMT+5", name: "Etc/GMT+5" },
  { id: "Etc/GMT+6", name: "Etc/GMT+6" },
  { id: "Etc/GMT+7", name: "Etc/GMT+7" },
  { id: "Etc/GMT+8", name: "Etc/GMT+8" },
  { id: "Etc/GMT+9", name: "Etc/GMT+9" },
  { id: "Etc/GMT-0", name: "Etc/GMT-0" },
  { id: "Etc/GMT-1", name: "Etc/GMT-1" },
  { id: "Etc/GMT-10", name: "Etc/GMT-10" },
  { id: "Etc/GMT-11", name: "Etc/GMT-11" },
  { id: "Etc/GMT-12", name: "Etc/GMT-12" },
  { id: "Etc/GMT-13", name: "Etc/GMT-13" },
  { id: "Etc/GMT-14", name: "Etc/GMT-14" },
  { id: "Etc/GMT-2", name: "Etc/GMT-2" },
  { id: "Etc/GMT-3", name: "Etc/GMT-3" },
  { id: "Etc/GMT-4", name: "Etc/GMT-4" },
  { id: "Etc/GMT-5", name: "Etc/GMT-5" },
  { id: "Etc/GMT-6", name: "Etc/GMT-6" },
  { id: "Etc/GMT-7", name: "Etc/GMT-7" },
  { id: "Etc/GMT-8", name: "Etc/GMT-8" },
  { id: "Etc/GMT-9", name: "Etc/GMT-9" },
  { id: "Etc/GMT0", name: "Etc/GMT0" },
  { id: "Etc/Greenwich", name: "Etc/Greenwich" },
  { id: "Etc/UCT", name: "Etc/UCT" },
  { id: "Etc/UTC", name: "Etc/UTC" },
  { id: "Etc/Universal", name: "Etc/Universal" },
  { id: "Etc/Zulu", name: "Etc/Zulu" },
  { id: "Europe/Amsterdam", name: "Europe/Amsterdam" },
  { id: "Europe/Andorra", name: "Europe/Andorra" },
  { id: "Europe/Astrakhan", name: "Europe/Astrakhan" },
  { id: "Europe/Athens", name: "Europe/Athens" },
  { id: "Europe/Belfast", name: "Europe/Belfast" },
  { id: "Europe/Belgrade", name: "Europe/Belgrade" },
  { id: "Europe/Berlin", name: "Europe/Berlin" },
  { id: "Europe/Bratislava", name: "Europe/Bratislava" },
  { id: "Europe/Brussels", name: "Europe/Brussels" },
  { id: "Europe/Bucharest", name: "Europe/Bucharest" },
  { id: "Europe/Budapest", name: "Europe/Budapest" },
  { id: "Europe/Busingen", name: "Europe/Busingen" },
  { id: "Europe/Chisinau", name: "Europe/Chisinau" },
  { id: "Europe/Copenhagen", name: "Europe/Copenhagen" },
  { id: "Europe/Dublin", name: "Europe/Dublin" },
  { id: "Europe/Gibraltar", name: "Europe/Gibraltar" },
  { id: "Europe/Guernsey", name: "Europe/Guernsey" },
  { id: "Europe/Helsinki", name: "Europe/Helsinki" },
  { id: "Europe/Isle_of_Man", name: "Europe/Isle_of_Man" },
  { id: "Europe/Istanbul", name: "Europe/Istanbul" },
  { id: "Europe/Jersey", name: "Europe/Jersey" },
  { id: "Europe/Kaliningrad", name: "Europe/Kaliningrad" },
  { id: "Europe/Kiev", name: "Europe/Kiev" },
  { id: "Europe/Kirov", name: "Europe/Kirov" },
  { id: "Europe/Lisbon", name: "Europe/Lisbon" },
  { id: "Europe/Ljubljana", name: "Europe/Ljubljana" },
  { id: "Europe/London", name: "Europe/London" },
  { id: "Europe/Luxembourg", name: "Europe/Luxembourg" },
  { id: "Europe/Madrid", name: "Europe/Madrid" },
  { id: "Europe/Malta", name: "Europe/Malta" },
  { id: "Europe/Mariehamn", name: "Europe/Mariehamn" },
  { id: "Europe/Minsk", name: "Europe/Minsk" },
  { id: "Europe/Monaco", name: "Europe/Monaco" },
  { id: "Europe/Moscow", name: "Europe/Moscow" },
  { id: "Europe/Nicosia", name: "Europe/Nicosia" },
  { id: "Europe/Oslo", name: "Europe/Oslo" },
  { id: "Europe/Paris", name: "Europe/Paris" },
  { id: "Europe/Podgorica", name: "Europe/Podgorica" },
  { id: "Europe/Prague", name: "Europe/Prague" },
  { id: "Europe/Riga", name: "Europe/Riga" },
  { id: "Europe/Rome", name: "Europe/Rome" },
  { id: "Europe/Samara", name: "Europe/Samara" },
  { id: "Europe/San_Marino", name: "Europe/San_Marino" },
  { id: "Europe/Sarajevo", name: "Europe/Sarajevo" },
  { id: "Europe/Saratov", name: "Europe/Saratov" },
  { id: "Europe/Simferopol", name: "Europe/Simferopol" },
  { id: "Europe/Skopje", name: "Europe/Skopje" },
  { id: "Europe/Sofia", name: "Europe/Sofia" },
  { id: "Europe/Stockholm", name: "Europe/Stockholm" },
  { id: "Europe/Tallinn", name: "Europe/Tallinn" },
  { id: "Europe/Tirane", name: "Europe/Tirane" },
  { id: "Europe/Tiraspol", name: "Europe/Tiraspol" },
  { id: "Europe/Ulyanovsk", name: "Europe/Ulyanovsk" },
  { id: "Europe/Uzhgorod", name: "Europe/Uzhgorod" },
  { id: "Europe/Vaduz", name: "Europe/Vaduz" },
  { id: "Europe/Vatican", name: "Europe/Vatican" },
  { id: "Europe/Vienna", name: "Europe/Vienna" },
  { id: "Europe/Vilnius", name: "Europe/Vilnius" },
  { id: "Europe/Volgograd", name: "Europe/Volgograd" },
  { id: "Europe/Warsaw", name: "Europe/Warsaw" },
  { id: "Europe/Zagreb", name: "Europe/Zagreb" },
  { id: "Europe/Zaporozhye", name: "Europe/Zaporozhye" },
  { id: "Europe/Zurich", name: "Europe/Zurich" },
  { id: "GB", name: "GB" },
  { id: "GB-Eire", name: "GB-Eire" },
  { id: "GMT", name: "GMT" },
  { id: "GMT+0", name: "GMT+0" },
  { id: "GMT-0", name: "GMT-0" },
  { id: "GMT0", name: "GMT0" },
  { id: "Greenwich", name: "Greenwich" },
  { id: "HST", name: "HST" },
  { id: "Hongkong", name: "Hongkong" },
  { id: "Iceland", name: "Iceland" },
  { id: "Indian/Antananarivo", name: "Indian/Antananarivo" },
  { id: "Indian/Chagos", name: "Indian/Chagos" },
  { id: "Indian/Christmas", name: "Indian/Christmas" },
  { id: "Indian/Cocos", name: "Indian/Cocos" },
  { id: "Indian/Comoro", name: "Indian/Comoro" },
  { id: "Indian/Kerguelen", name: "Indian/Kerguelen" },
  { id: "Indian/Mahe", name: "Indian/Mahe" },
  { id: "Indian/Maldives", name: "Indian/Maldives" },
  { id: "Indian/Mauritius", name: "Indian/Mauritius" },
  { id: "Indian/Mayotte", name: "Indian/Mayotte" },
  { id: "Indian/Reunion", name: "Indian/Reunion" },
  { id: "Iran", name: "Iran" },
  { id: "Israel", name: "Israel" },
  { id: "Jamaica", name: "Jamaica" },
  { id: "Japan", name: "Japan" },
  { id: "Kwajalein", name: "Kwajalein" },
  { id: "Libya", name: "Libya" },
  { id: "MET", name: "MET" },
  { id: "MST", name: "MST" },
  { id: "MST7MDT", name: "MST7MDT" },
  { id: "Mexico/BajaNorte", name: "Mexico/BajaNorte" },
  { id: "Mexico/BajaSur", name: "Mexico/BajaSur" },
  { id: "Mexico/General", name: "Mexico/General" },
  { id: "NZ", name: "NZ" },
  { id: "NZ-CHAT", name: "NZ-CHAT" },
  { id: "Navajo", name: "Navajo" },
  { id: "PRC", name: "PRC" },
  { id: "PST8PDT", name: "PST8PDT" },
  { id: "Pacific/Apia", name: "Pacific/Apia" },
  { id: "Pacific/Auckland", name: "Pacific/Auckland" },
  { id: "Pacific/Bougainville", name: "Pacific/Bougainville" },
  { id: "Pacific/Chatham", name: "Pacific/Chatham" },
  { id: "Pacific/Chuuk", name: "Pacific/Chuuk" },
  { id: "Pacific/Easter", name: "Pacific/Easter" },
  { id: "Pacific/Efate", name: "Pacific/Efate" },
  { id: "Pacific/Enderbury", name: "Pacific/Enderbury" },
  { id: "Pacific/Fakaofo", name: "Pacific/Fakaofo" },
  { id: "Pacific/Fiji", name: "Pacific/Fiji" },
  { id: "Pacific/Funafuti", name: "Pacific/Funafuti" },
  { id: "Pacific/Galapagos", name: "Pacific/Galapagos" },
  { id: "Pacific/Gambier", name: "Pacific/Gambier" },
  { id: "Pacific/Guadalcanal", name: "Pacific/Guadalcanal" },
  { id: "Pacific/Guam", name: "Pacific/Guam" },
  { id: "Pacific/Honolulu", name: "Pacific/Honolulu" },
  { id: "Pacific/Johnston", name: "Pacific/Johnston" },
  { id: "Pacific/Kiritimati", name: "Pacific/Kiritimati" },
  { id: "Pacific/Kosrae", name: "Pacific/Kosrae" },
  { id: "Pacific/Kwajalein", name: "Pacific/Kwajalein" },
  { id: "Pacific/Majuro", name: "Pacific/Majuro" },
  { id: "Pacific/Marquesas", name: "Pacific/Marquesas" },
  { id: "Pacific/Midway", name: "Pacific/Midway" },
  { id: "Pacific/Nauru", name: "Pacific/Nauru" },
  { id: "Pacific/Niue", name: "Pacific/Niue" },
  { id: "Pacific/Norfolk", name: "Pacific/Norfolk" },
  { id: "Pacific/Noumea", name: "Pacific/Noumea" },
  { id: "Pacific/Pago_Pago", name: "Pacific/Pago_Pago" },
  { id: "Pacific/Palau", name: "Pacific/Palau" },
  { id: "Pacific/Pitcairn", name: "Pacific/Pitcairn" },
  { id: "Pacific/Pohnpei", name: "Pacific/Pohnpei" },
  { id: "Pacific/Ponape", name: "Pacific/Ponape" },
  { id: "Pacific/Port_Moresby", name: "Pacific/Port_Moresby" },
  { id: "Pacific/Rarotonga", name: "Pacific/Rarotonga" },
  { id: "Pacific/Saipan", name: "Pacific/Saipan" },
  { id: "Pacific/Samoa", name: "Pacific/Samoa" },
  { id: "Pacific/Tahiti", name: "Pacific/Tahiti" },
  { id: "Pacific/Tarawa", name: "Pacific/Tarawa" },
  { id: "Pacific/Tongatapu", name: "Pacific/Tongatapu" },
  { id: "Pacific/Truk", name: "Pacific/Truk" },
  { id: "Pacific/Wake", name: "Pacific/Wake" },
  { id: "Pacific/Wallis", name: "Pacific/Wallis" },
  { id: "Pacific/Yap", name: "Pacific/Yap" },
  { id: "Poland", name: "Poland" },
  { id: "Portugal", name: "Portugal" },
  { id: "ROC", name: "ROC" },
  { id: "ROK", name: "ROK" },
  { id: "Singapore", name: "Singapore" },
  { id: "Turkey", name: "Turkey" },
  { id: "UCT", name: "UCT" },
  { id: "US/Alaska", name: "US/Alaska" },
  { id: "US/Aleutian", name: "US/Aleutian" },
  { id: "US/Arizona", name: "US/Arizona" },
  { id: "US/Central", name: "US/Central" },
  { id: "US/East-Indiana", name: "US/East-Indiana" },
  { id: "US/Eastern", name: "US/Eastern" },
  { id: "US/Hawaii", name: "US/Hawaii" },
  { id: "US/Indiana-Starke", name: "US/Indiana-Starke" },
  { id: "US/Michigan", name: "US/Michigan" },
  { id: "US/Mountain", name: "US/Mountain" },
  { id: "US/Pacific", name: "US/Pacific" },
  { id: "US/Pacific-New", name: "US/Pacific-New" },
  { id: "US/Samoa", name: "US/Samoa" },
  { id: "UTC", name: "UTC" },
  { id: "Universal", name: "Universal" },
  { id: "W-SU", name: "W-SU" },
  { id: "WET", name: "WET" },
  { id: "Zulu", name: "Zulu" },
];

export const tournamentRestrictions = [
  { id: "SPR", name: "SPR" },
  { id: "PPR", name: "PPR" },
];
