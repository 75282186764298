import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registerApi = createApi({
  reducerPath: "registerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/register/`,
  }),
  endpoints: (builder) => ({
    getTeams: builder.mutation({
      query: (body) => ({
        url: `prereg-get-teams`,
        method: "POST",
        body: body,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: `prereg-register`,
        method: "POST",
        body: body,
      }),
    }),
    unRegister: builder.mutation({
      query: (body) => ({
        url: `prereg-unregister`,
        method: "POST",
        body: body,
      }),
    }),
    userSearch: builder.mutation({
      query: (body) => ({
        url: `search-user`,
        method: "POST",
        body: body,
      }),
    }),
    previousTeammateSearch: builder.mutation({
      query: (body) => ({
        url: `search-teammate`,
        method: "POST",
        body: body,
      }),
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: `create-user`,
        method: "POST",
        body: body,
      }),
    }),
    checkTournamentStatus: builder.mutation({
      query: (body) => ({
        url: `check-status`,
        method: "POST",
        body: body,
      }),
    }),
    checkTournamentStatusV2: builder.mutation({
      query: (body) => ({
        url: `v2/check-status`,
        method: "POST",
        body: body,
      }),
    }),
    updateWaitlist: builder.mutation({
      query: (body) => ({
        url: `update-waitlist`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetTeamsMutation,
  useRegisterMutation,
  useUnRegisterMutation,
  useUserSearchMutation,
  useCreateUserMutation,
  useCheckTournamentStatusMutation,
  useCheckTournamentStatusV2Mutation,
  useUpdateWaitlistMutation,
  usePreviousTeammateSearchMutation
} = registerApi;
